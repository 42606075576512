/*
######################
### CSS GUIDELINES ###
######################
Refer to these guidelines when adding styles to this document.

High level:
- Use flexible/responsive units whenever possible; things like rem, em, %, etc, rather than px.
- Avoid overriding existing styles. This creates a lot of duplicate styles and can grow confusing.
- Use descriptive class names.
- Add a 'tag' as a comment above any newly created section specific code. ie a new page or utility that uses specific styles
    The format for tags are: 
      || SECTION NAME. 
      ie, || PATIENTS PAGE
    Use the '||' identifier to search through available tags. Do not use the same first word to keep tags unique.

Coding style:
- Use expanded (multiline) syntax)
- Use double quotes when necessary ("").
- If using commas to reference multiple classes or elements, put each on a new line.
- Use specific long form styles rather than shorthand styles for clarity and to avoid unwanted defaults.
- Avoid using !important; we should not be in a situation where we are overriding other styles.
- Use the root styles for font and color (--clr-red-900, --ff-primary, etc) for consistency.
- Avoid overly specific selectors for components if possible. Components should have standard styles across the site, unless necessary.
- Add one (1) new line between each style block.
- Label whenever you're overriding or customizing Booststrap CLASSES.
*/
/* Style Tags Index:
 || GENERAL STYLES 
 || TABLES 
 || TABS
 || CARDS 
 || FORMS 
 || ACCORDIONS 
 || ALERTS 
 || NOTIFICATIONS 
 || MODALS 
 || NAVIGATION 
 || DROPDOWN
 || BUTTONS 
 || MEDIA QUERY
 || SCROLLBAR 
 || CUSTOM COMPONENTS 
*/

/* TODO:
- Change all instances of font-family to use the root fonts
- Change all instances of (background-)color to use root colors
- Standardize font sizes
- Remove unnecessary styles
- EVALUATE: BUTTONS, CARDS, TABLES, FORMS, NAVIGATION, MEDIA QUERY, CUSTOM
*/
/* PENDING DELETION 
######################

.md-form .form-control {
  padding: 1rem;
} 
*/
/* body,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
} */

/* || GENERAL STYLES */

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dosis:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap");

:root {
  --clr-primary-100: #f8f9fa;
  --clr-primary-200: #ccd2e3;
  --clr-neutral-100: #fff;
  --clr-neutral-200: #f5f5f5;
  --clr-neutral-400: #7c7c7c;
  --clr-neutral-500: 3pt 7pt 7pt rgb(0 0 0 / 15%);
  --clr-neutral-900: #222c2a;
  --clr-red-900: #932824;
  --clr-blue-200: #449dd1;
  --clr-primary-500: #4592f1;
  --clr-primary-fade: #2e84ec;
  --clr-success: rgb(63, 184, 52);
  --clr-error-900: lightcoral;
  --clr-error-fade: rgb(235, 112, 112);
  --clr-blue-900: #31447c;
  --ff-primary: "Roboto", sans-serif;
  --ff-accent: "Playfair Display", serif;
  --ff-header: "Poppins", sans-serif;
  --collapse-line-height: 6rem;
}

html {
  font-size: 10px;
}

body {
  font-family: var(--ff-primary);
  font-weight: 300;
  font-size: 1.4125rem;
  line-height: 1.6;
  background: url("https://clshealthblob.blob.core.windows.net/clslogos/Untitled design (1).png")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: overlay;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*::selection {
  background: var(--clr-blue-900);
  color: white;
}

h1 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 4rem !important;
  font-variant: normal;
}

h2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 4rem !important;
  font-variant: normal;
  color: var(--clr-blue-900);
  letter-spacing: 0px;
}

.rankingsText {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  font-size: 1.75rem !important;
  font-variant: normal;
  line-height: 1;
}

.h3 {
  font-family: var(--ff-header);
  font-weight: 300;
  color: var(--clr-neutral-900);
  line-height: 1;
  /* font-family: 'Dosis', 'sans-serif'; */
}

.h4 {
  font-family: var(--ff-accent);
  font-weight: 900;
  font-size: 1.5rem !important;
  color: var(--clr-neutral-900);
  line-height: 1;
}

h4 .row .col {
  min-width: fit-content;
}

.h5 {
  font-size: 2.5em;
}

h6 {
  font-size: 1.1rem;
  color: gray;
}

.h7 {
  font-size: 3rem !important;
  font-weight: 600 !important;
}

.h8 {
  font-size: 2rem;
}

.h9 {
  font-size: 1.8rem !important;
  color: black;
  display: flex;
  justify-content: center !important;
}

.buffer {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  color: black !important;
  font-weight: 600;
}

.poppins-text {
  color: #31447c;
  font: "Poppins";
  font-weight: 700;
  font-size: 24px;
}

.billingInfoTitle {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  font-size: 3rem !important;
  line-height: 100% !important;
  letter-spacing: 0.01rem !important;
  color: #31447c !important;
  padding-top: 3rem !important;
}

.priceText {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  margin-bottom: 0px !important;
  min-width: 6rem !important;
  text-align: right !important;
  color: black !important;
}

.changeText {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 100% !important;
  text-align: right !important;
  color: #4592f1 !important;
  padding-right: 0px !important;
  align-items: center !important;
}

.boldTitle {
  font-size: 2rem;
  font-weight: bold;
}

.subtitle {
  color: #787878;
  /* font-style: italic; */
  font-size: 1.4rem;
  font-weight: 400;
}

.gray-hover:hover {
  background-color: #d9dee3;
  transition: 0.2s ease-in-out;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.underline-hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 0.1em;
  background-color: gray;
  transition: width 300ms;
}

.underline-hover:hover::after {
  width: 100%;
}

.circle {
  max-width: 10px !important;
  max-height: 15px !important;
  min-height: 15px !important;
}

.kudosCardScroll {
  max-height: 74rem;
  overflow: hidden;
  padding-right: 2.2rem;
  transition: 0.35s;
}

.kudosCardScroll:hover {
  overflow-y: overlay;
}

p {
  max-width: 70ch;
}

p.p2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 2rem !important;
  font-variant: normal;
  color: var(--clr-blue-900);
  margin-block: 0;
}

.col-width {
  font-size: 1.5rem;
}

/* .row {
  align-items: center;
} */

section {
  padding: 3rem 0;
}

.card {
  margin-block: 1.5rem;
}

span {
  color: black;
  font-weight: 500;
}

/* || TABLES */
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  box-shadow: var(--clr-neutral-500);
  background: white;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: inherit !important;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--clr-blue-900);
  color: white;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  transition-duration: 0.15s;
  background-color: #ddd;
}

th.searchBar {
  background-color: #f2f2f2;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #ddd;
  padding: 8px;
}

.filter-input {
  font-size: 1.1rem;
  border: 2;
  color: black;
  width: 7.5rem !important;
}

.pagination button.btn.btn-primary {
  font-size: 1em;
  margin-inline: 0.2rem;
}

.pagination {
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

/* || TABS */
.tab-content {
  padding: 1rem;
}

/* || CARDS */
/* bootstrap */

.stream-card {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px !important;
  border-radius: 0% !important;
}

.card {
  background-color: #fafaff;
  border: 1px solid rgba(169, 169, 169, 0.288) !important;
  padding: 2rem 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.75rem;
}

.cardRankings {
  background-color: #fafaff;
  border: 1px solid rgba(169, 169, 169, 0.288) !important;
  padding: 1.5rem 1.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1rem;
}

.card-title {
  /* font-family: 'Dosis'; */
  font-weight: 500;
  text-align: left;
}

.circleJawn {
  display: flex;
  height: 2.2rem;
  width: 2.2rem;
  background-color: goldenrod;
  border-radius: 50%;
  justify-content: center;
  text-align: center;
}

.card-footer,
.card-header {
  background-color: transparent;
}

/* || CAROUSEL */
.payment-carousel .carousel-inner {
  padding: 2px;
}

.form-signin .carousel-inner {
  padding: 4rem 3rem;
}

.carousel-inner {
  padding: 2px;
}

/* || FORMS */
input.form-control {
  border-radius: 0.75rem;
}

input[type="checkbox"],
input[type="radio"] {
  margin-block: 0.55rem;
}

.form-check-label {
  margin-inline: 0rem !important;
}

.form-check-input:checked[type="radio"] {
  background-image: none !important;
}

.md-form label {
  font-size: 1.4rem !important;
  margin-left: 0.5rem;
}

.md-form textarea.md-textarea {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* input[type="date"].form-control {
  margin-top: none;
} */
/* Firefox Compatibility */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  margin-left: 1rem;
  min-width: 15rem;
}

label {
  font-size: 1.2em;
  font-weight: 400;
}

.form-check-label {
  /* bootstrap */
  margin-inline: 2rem;
}

.form-control {
  margin-block: 0.5rem;
  font-size: inherit;
}

.form-control::placeholder {
  font-weight: 40;
  font-size: 1.45rem;
}

textarea {
  border-radius: 0.75rem !important;
}

.editableTitle {
  background-color: transparent;
  border: none;
  font-size: 2.5rem;
  padding: 0;
}

.editableTitle:focus {
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}

.editableField {
  background-color: transparent;
  border: none;
  margin-block: 0;
}

.editableField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editableField:focus {
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: none;
}

.dropdown-toggle::after {
  display: none;
}

.btn-primary.dropdown-toggle {
  background-color: transparent !important;
  padding: 0.25rem !important;
}

.btn-primary.dropdown-toggle:focus {
  background-color: transparent !important;
}

.btn-primary.dropdown-toggle:not([disabled]):not(.disabled):active,
.btn-primary.dropdown-toggle:hover,
.btn-primary.dropdown-toggle:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: lightgray !important;
}

.btn-primary.dropdown-toggle:active:active:focus,
.btn-primary.dropdown-toggle:active:focus {
  outline: none;
  background-color: rgb(170, 170, 170) !important;
}

/* || ALERTS */
.error {
  color: red;
  font-size: 15pt;
  margin-top: 2%;
  margin-left: 1.4%;
  /* margin-right: 25%; */
  white-space: pre-wrap;
}

.pwerror {
  color: red;
  font-size: 11pt;
  margin-top: 2%;
  margin-left: 1.4%;
  white-space: pre-line;
}

/* || NOTIFICATIONS */
/* || MODALS */
.modal-dialog .modal-content {
  border-radius: 0.75rem;
}

.modal-footer {
  justify-content: left;
}

/* || ACCORDIONS */
.accordion-button {
  /* bootstrap */
  font-size: initial;
  font-weight: normal;
  transition: 0.15s;
}

.accordion-button:hover {
  /* bootstrap */
  background-color: aliceblue;
}

/* || NAVIGATION */

.navigation {
  background: rgb(255, 255, 255);
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  z-index: 999;
  height: 6rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.app-link:hover {
  background-color: #e8f0fe !important;
  border: none !important;
  border-radius: 8px !important;
  margin: 0 !important;
  z-index: 1 !important;
}

.app-link:hover .app-title {
  white-space: normal !important;
  word-wrap: break-word !important;
  background-color: #e8f0fe !important;
  text-decoration: none !important;
}

.appnav {
  display: inline-block !important;
  list-style-type: none;
  padding: 6px !important;
  position: relative !important;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1) !important;
  vertical-align: top !important;
  height: 96px;
  width: 96px;
  background-color: transparent !important;
}

.app-link {
  border-radius: 8px !important;
  display: unset !important;
  margin: 0 !important;
  outline: none !important;
  position: absolute !important;
  text-align: center !important;
  text-decoration: none !important;
  width: 84px !important;
  padding: 0 !important;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1) !important;
}

.img-div {
  height: 62px !important;
  transform: scale(calc(5 / 6)) !important;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1) !important;
}

.app-title {
  color: #202124 !important;
  display: inline-block !important;
  font-size: 14px !important;
  letter-spacing: 0.09px !important;
  line-height: 18px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 76px !important;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1) !important;
}

.app-img {
  display: inline-block !important;
  height: 64px !important;
  vertical-align: top !important;
  width: 64px !important;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1) !important;
}

.icon-left {
  border-bottom: 5px solid transparent;
  border-right: 5px solid #4273db;
  border-top: 5px solid transparent;
  float: left;
  left: 0;
  top: 26px;
}

.icon-right {
  border-bottom: 5px solid transparent;
  border-left: 5px solid #4273db;
  border-top: 5px solid transparent;
  float: right;
  left: 79px;
  top: 26px;
}

.icon-bottom {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #4273db;
  left: 36px;
  top: 57px;
}

.icon-top {
  border-bottom: 5px solid #4273db;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 36px;
  top: 0;
}

.hidden-borders {
  height: 0;
  position: absolute;
  width: 0;
  visibility: hidden;
  display: block;
}

.my-tooltip {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: normal;
  padding: 0 !important;
  margin: 0;
}

.RowPaddedCard .row {
  padding-bottom: 0.5rem;
}

.nav-icon-button {
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}

.nav-icon-button:hover {
  background-color: #e8f0fe !important;
  border-radius: 50%;
  min-width: 4rem;
}

.nav-icon-button:hover,
.nav-icon-button:active {
  transform: rotate(-90deg);
}

.appnav-settings {
  margin-block: 0.5rem;
}

.appnav-settings:hover {
  background-color: #e8f0fe !important;
  border-radius: 1rem;
}

.menu-bars {
  color: whitesmoke;
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 2em;
  transition: transform 0.15s ease-in-out;
}

.menu-bars:hover {
  cursor: pointer;
  transform: scale(1.15);
  transition: transform 0.15s ease-in-out;
}

.nav-menu {
  height: 100%;
  max-height: calc(100vh - 5rem);
  width: min(30%, 20rem);
  margin-right: 2rem;
  min-width: fit-content;
  overflow-y: auto;
  position: fixed;
  top: 9rem;
  background: var(--clr-primary-100);
  box-shadow: var(--clr-neutral-500);
  z-index: 1;
  transform: scale(0, 1);
  transition: transform 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: left;
}

.nav-menu.active {
  transform: scale(1, 1);
  transition: transform 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: left;
}

.nav-menu ul {
  padding: 0;
  list-style: none;
}

.menu-links {
  text-indent: 1em;
  display: block;
  color: var(--clr-neutral-900);
  font-size: 1.2em;
  text-transform: uppercase;
  background-color: inherit;
  border-left: inherit;
  transition: background-color 0.15s;
  transition: border-left 0.15s;
  background-color: inherit;
  width: 100%;
}

.menu-links:hover {
  background-color: #e8e8e8;
  color: #173070;
  border-left: 4px solid var(--clr-blue-900);
  cursor: pointer;
}

.logo-title {
  display: none;
}

.mainnav {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  padding-right: 3rem;
  font-family: "Dosis";
}

.mainnav a {
  display: flex;
  color: #31447c;
  text-decoration: none;
  font-size: 1.2em;
  text-transform: lowercase;
  background-color: inherit;
  font-weight: 600;
  transition: background-color 0.1s ease-in-out !important;
  height: 100%;
  align-items: center;
  padding-inline: 1.5rem;
  user-select: none;
}

.mainnav a:hover {
  background-color: #e8e8e8;
  color: #173070;
  transition: background-color 0.1s ease-in-out !important;
}

/* || DROPDOWN */
.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  font-size: 1.4rem;
}

.btn-primary.dropdown-toggle {
  background-color: transparent !important;
  padding: 0.25rem !important;
}

.btn-primary.dropdown-toggle:focus {
  background-color: transparent !important;
}

.btn-primary.dropdown-toggle:not([disabled]):not(.disabled):active,
.btn-primary.dropdown-toggle:hover,
.btn-primary.dropdown-toggle:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: lightgray !important;
}

.btn-primary.dropdown-toggle:active:active:focus,
.btn-primary.dropdown-toggle:active:focus {
  outline: none;
  background-color: rgb(170, 170, 170) !important;
}

/* || BUTTONS */
.btn {
  /* bootstrap */
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
  margin-right: 0.375rem !important;
  margin-left: 0rem !important;

  border-radius: 0.35rem !important;
  font-size: inherit !important;
  box-shadow: none !important;
  background-color: lightseagreen;
  color: whitesmoke !important;
}

.lookup-color {
  background-color: "#0E4DA0" !important;
  font-weight: "bold";
  font-size: "1.5em";
}

.btn:hover {
  /* bootstrap */
  background-color: var(--clr-primary-fade);
}

.btn.btn-lg {
  font-size: 2rem !important;
}

.cls-icon {
  width: 7rem;
  margin-inline: 0.5rem;
  margin-block: 2rem;
}

.row h2 {
  font-size: 2.4rem;
}

.InputRow {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.red_col {
  background-color: blanchedalmond;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 0.9rem 0 0 0.9rem;
}

.btn:active:focus,
.btn:focus {
  /* bootstrap */
  outline-color: aliceblue;
  outline-style: dashed;
  outline-width: 0.1rem;
  outline-offset: -2px;
}
.btn-close:focus {
  box-shadow: none;
  transform: scale(0.9);
  transition: 0.15s;
}

/* custom cls buttons */
.btn-cls {
  background-color: var(--clr-primary-500) !important;
}

.btn-cls-danger {
  background-color: var(--clr-error-900) !important;
}

.btn-cls-danger:hover {
  background-color: var(--clr-error-fade) !important;
}

.btn-cls-success {
  background-color: var(--clr-success) !important;
}

.link {
  color: black;
}

.portal-btn {
  padding-right: 3rem;
  padding-left: 3rem;
}

/* || MEDIA QUERY */
/* || CUSTOM COMPONENTS */
.VirtualSearchBar {
  background-color: white;
  padding: 0.5rem;
  border-style: solid;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: lightgray;
}

.VirtualSearchList {
  /* padding: 1rem; */
  margin-top: 0.5rem;
  background-color: white;
  z-index: 999;
  position: absolute !important;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 0 0 1rem 1rem;
  border-style: solid;
  /* overflow: hidden; */
}

.VirtualElement {
  cursor: pointer;
  padding: 1rem;
}

.VirtualElement:hover {
  background-color: lightblue;
}
.VirtualItems {
  display: flex;
}
.VirtualItemSelected {
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 0.2rem;
  background-color: lightgray;
}

.info-card {
  background-color: rgb(238, 238, 238);
  border-radius: 0.3rem;
  padding: 0.4rem;
  color: gray;
  font-style: italic;
}
.info-card h3 {
  padding-top: 1rem;
  font-style: normal;
  font-weight: bold !important;
  color: var(--clr-blue-900);
}

.warning {
  font-style: italic;
  color: orange;
}
.red-on-hover {
  transition: 0.3s;
}

.red-on-hover:hover {
  color: red;
  transition: 0.3s;
}

.wiggly {
  animation: wiggle 0.5s;
}

@keyframes wiggle {
  15% {
    transform: translateX(1rem);
  }

  30% {
    transform: translateX(-1rem);
  }

  45% {
    transform: translateX(0.5rem);
  }

  60% {
    transform: translateX(-0.5rem);
  }

  75% {
    transform: translateX(0.25rem);
  }

  100% {
    transform: translateX(0);
  }
}

.spin {
  animation: spinning 1s linear infinite;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@media screen and (max-width: 1280px) {
  .quik-tag .quik-content {
    display: none;
  }
}

.quik-tag {
  content: normal;
  position: fixed;
  z-index: 999;
  left: 0%;
  width: fit-content;
  top: 10%;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.quik-tag-hidden {
  top: 10%;
  left: -30%;
  z-index: 999;
  width: fit-content;
  position: fixed;
  transition: 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.quik-bar {
  position: fixed;
  z-index: 998;
  max-width: 40rem;
  min-width: 22%;
  left: 1%;
  top: 10%;
  height: fit-content;
  min-height: 50%;
  max-height: 75%;
  overflow: scroll;
  transition: 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.quik-bar-hidden {
  position: fixed;
  z-index: 998;
  max-width: 40rem;
  min-width: 22%;
  top: 10%;
  left: -100%;
  height: fit-content;
  min-height: 50%;
  max-height: 75%;
  overflow: scroll;
  transition: 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.partial-collapse.collapse:not(.show) {
  height: var(--collapse-line-height);
  display: block;
  overflow: hidden;
}

.partial-collapse.collapsing {
  height: var(--collapse-line-height);
}

.v-align {
  display: flex;
  align-items: center;
}

.h-align {
  display: flex;
  justify-content: center;
}

.progress {
  padding: 0px;
}

.progress-label {
  display: flex;
  justify-content: space-between;
}

.fw-300 {
  font-weight: 300;
}

.fw-150 {
  font-weight: 150;
}

.animated .fadeIn {
  z-index: -10000;
}

.card-button,
.card-logout {
  transition-duration: 0.375s;
  align-items: center;
  line-height: 2rem;
}

.card-button:hover {
  background-color: var(--clr-primary-500) !important;
  color: white;
  transition-duration: 0.375s;
  font-size: 2rem;
}

.card-logout:hover {
  background-color: var(--clr-error-900) !important;
  color: white;
  text-indent: 10%;
  transition-duration: 0.375s;
}

.card.infusion-card {
  padding: 0;
}

.card-title.infusion-title {
  text-align: inherit;
}

.fill-width {
  width: 100% !important;
}

.doctor,
.specialty,
.location {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.5em;
}

.oncall-search {
  display: block;
  justify-content: flex-start;
  align-items: center;
}

.width-35 {
  width: 35%;
  padding-inline: 0.35rem;
}

.mycontainer {
  margin-inline: auto;
  width: min(90%, 100rem);
  padding-block: 8rem;
  align-content: center;
  margin-top: 3rem;
  position: relative;
}

.pdf-frame {
  height: 100rem;
}

.center {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.input-group .form-control {
  margin-block: 0;
}

.center.col {
  display: flex;
  justify-content: center;
  margin-block: 1rem;
}

.center-col-text {
  text-align: center;
}

.right-col-text {
  text-align: right;
}

.btn-default {
  background-color: var(--clr-error-900) !important;
}

.fit-content {
  width: fit-content;
}

.home-icons {
  width: 10rem;
  margin-bottom: 10px;
}

.blue-text-bold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 4rem !important;
  font-variant: normal;
  color: var(--clr-blue-900);
}

.red-text-bold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 4rem !important;
  font-variant: normal;
  color: var(--clr-red-900);
}

.red-text {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 3rem !important;
  font-variant: normal;
  color: var(--clr-red-900);
}

.hover-bg:hover {
  background-color: lightgray;
  transition-duration: 0.2s;
}

.hover-bg {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  transition-duration: 0.2s;
}

/* || LOGIN CARD */
.loginForm {
  display: flex;
  justify-content: center;
  margin-top: 8%;
}

.form-signin {
  width: 80%;
  margin: inherit;
}

/* || SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 1.2rem;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
  transition: 0.35s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #414141;
}

/* || TOOLTIPS */
.tooltip-inner {
  font-size: 1.2rem;
  margin: 0;
}

.popover[x-placement^="right"] {
  margin-left: 0 !important;
  /* hanldes warning, popover can't have margins? */
}

.popover {
  font-size: 1.2rem;
}

.popover-header {
  font-size: 1.2rem;
  background-color: #173070;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.badge-i {
  font-weight: bold;
  font-size: smaller;
  font-family: ui-serif;
}

.no-margin {
  margin-top: 0.5rem;
  margin-inline: 0;
  padding-inline-end: 0;
}

@media screen and (min-width: 750px) {
  .form-signin {
    width: 40%;
  }

  .navigation {
    display: grid;
    grid-template-columns: 0.25fr 2fr 1fr;
    align-items: center;
    /* padding-block: 1rem; */
  }

  .nav-toggle-label {
    grid-column: 1 / 1;
  }

  .nav-toggle {
    grid-column: 1/1;
  }

  .logo-title {
    display: inline-flex;
    flex-wrap: nowrap;
    user-select: none;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .logo-title:hover {
    transform: scale(1.2);
  }

  .title {
    color: whitesmoke;
    position: relative;
    font-size: 3em;
    user-select: none;
    width: fit-content;
    text-transform: lowercase;
  }

  .title h1 {
    font-family: "Dosis", sans-serif !important;
    font-weight: 600 !important;
  }

  .cls-logo {
    display: block;
    width: 23rem;
    margin-left: 30px;
  }

  .mainnav {
    grid-column: 3 / 3;
    flex-wrap: nowrap;

    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    justify-content: flex-end;
  }

  .mainnav a {
    width: fit-content;
  }
}

@media screen and (max-width: 480px) {
  table td,
  table th {
    font-size: small !important;
    border: 1px solid #ddd;
    padding: 0.4rem;
  }

  table th {
    font-size: 12px !important;
  }
}

.margin-button {
  margin-inline-end: 0.375rem !important;
}

.MuiTypography-root {
  max-width: unset;
  width: 100%;
}

/* || STRIKE */
.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: lightgray;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: unset;
  transition: 0.22s;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 2rem;
}

.form-floating > label {
  color: #787878;
}

/* figure out a way to delete this */
.col-sm-1 {
  width: 62% !important;
  margin-bottom: 0px;
}

.col-sm-2 {
  width: 27% !important;
}

.col-sm-3 {
  width: 25% !important;
}

.col-sm-4 {
  width: 30% !important;
  text-align: center;
  justify-content: right;
}
