.facility-card {
  background-color: initial;
  transition: 0.25s;
}

.facility-card:hover {
  background-color: #e43b3521;
}

.marker {
  width: 3rem;
  fill: #ea4335;
  stroke: #b31412;
  stroke-width: 1px;
}

.patient-marker {
  background-color: #4185f4;
  border: 2.5px solid white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  box-shadow: 2px 2px 8px gray;
  animation: load 1.5s ease-out infinite;
}

.marker-container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 100%;
}

.marker-idx {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: -1rem;
}

.address-input::placeholder {
  font-weight: 400;
}

.marker-container.wiggle-marker {
  animation: wiggle 2s cubic-bezier(0.42, 0, 0.58, 1);
}

.schedule-container {
  font-size: 1.1rem;
  color: gray;
  font-weight: 500;
}

@keyframes wiggle {
  0% {
    transform: rotate(-10deg);
  }
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(8deg);
  }
  40% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(6deg);
  }
  60% {
    transform: rotate(-6deg);
  }
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-4deg);
  }
  90% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes load {
  0% {
    box-shadow: 0 0 0 0 #4185f4;
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
